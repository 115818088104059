/*
*  Admin styles
// scss-lint:disable ImportantRule, QualifyingElement, IdSelector, ColorVariable
*/

#postexcerpt {
  textarea + p {
    display: none;
  }
}

.form-steps-admin {
  padding: 0 !important;

  .mce-edit-area > iframe {
    height: 200px !important;
  }

  > .acf-input {
    top: -1px;
    left: -1px;
  }

  .left-panel-group > .acf-input > .acf-fields {
    border: 3px solid #f0f0f1 !important;
    background: #f0f0f1 !important;
    border-radius: 8px;
    overflow: hidden;

    > .acf-field {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }
  }

  .no-table-head {
    thead {
      display: none;
    }
  }

  .acf-fields.-left > .acf-field {
    padding: 8px 0;
  }

  .acf-repeater.-row > table > tbody > tr > td {
    border-top-width: 26px !important;
  }

  .no-label > .acf-label,
  &.no-label > .acf-label {
    display: none;
  }

  .layout {
    border: 3px solid #abc !important;
    border-radius: 8px;
    overflow: hidden;

    > .acf-fields > .acf-field {
      padding: 10px 12px !important;
    }
  }

  .layout > .acf-fc-layout-handle {
    background: #abc !important;
    color: #fff !important;
  }
}

/*
*	ACF repeater border stroke
*/

.acf-repeater.-row > table > tbody > tr > td,
.acf-repeater.-block > table > tbody > tr > td {
  border-top-width: 4px;
  border-top-color: #abc;
}

.acf-repeater.-row table > tbody > tr:first-child td,
.acf-repeater.-block table > tbody > tr:first-child > td {
  border-top-width: 0;
}

input[type=text]:disabled {
  color: #000;
  cursor: text;
}

/*
*  Adjust sizes of some fields
*/

.size-adjust {
  .badge {
    font-size: 12px;
    font-weight: normal;
    padding-left: 7px;
    padding-right: 7px;
  }

  ul.acf-radio-list li input[type="radio"] {
    margin-right: 2px;
  }

  ul.acf-radio-list.acf-hl li {
    margin-right: 12px;
  }

  i[class^="icon"] {
    font-size: 24px;
    position: relative;
    top: -3px;
  }
}

/* Media */


/* Media library */
.media-frame.mode-edit, {
  .media-sidebar {
    .compat-item tr {
      display: table-row;
    }

    .media-types-required-info,
    .compat-item .compat-field-acf-form-data {
      display: none;
    }

    .compat-attachment-fields tbody tr {
      .acf-label {
        min-width: 34%;
        padding: 10px 10px 0 0;

        label {
          font-weight: normal;
          text-align: right;
          color: #666;
        }
      }

      .acf-input {
        width: 66%;
        padding: 6px 0 0;

        input {
          width: 100%;
          font-size: 12px;
          padding: 6px 8px;
        }
      }
    }

    th.label.eml-tax-label {
      vertical-align: top;
      text-align: right;
      display: table-cell;
      padding-right: 10px;

      span.alignleft {
        text-align: right;
      }
    }
  }
}

/* Media library popup */
.edit-attachment-frame.mode-select {
  .media-sidebar {
    .media-types-required-info {
      display: none;
    }
  }
}

/* Media select popup */
.media-frame.mode-select {
  .media-types-required-info {
    display: none;
  }

  .media-sidebar {
    .acf-field input[type="text"] {
      font-size: 12px;
    }

    .compat-attachment-fields tbody tr {
      .acf-label {
        width: 34%;
        padding: 4px 10px 0 0;

        label {
          font-weight: normal;
          text-align: right;
          color: #666;
          font-size: 12px;
        }
      }

      .acf-input {
        width: 66%;
        padding: 6px 0 0;

        input {
          width: 100%;
          font-size: 12px;
          padding: 6px 8px;
        }
      }
    }
  }
}

/*
*  Column width
*/

th.manage-column#astuce,
th.manage-column#ressource {
  width: 75px;
  max-width: 75px;
  text-align: center;
}

.column-astuce,
.column-ressource {
  width: 75px;
  max-width: 75px;
  text-align: center;
}

/*
*  Yoast Premium
*/

#yoast-help-center-container,
.wpseo-metabox-buy-premium,
.yoast-notice-go-premium {
  display: none;
}

/*
*  Editor role
*/

.role-editor {
  .subsubsub {
    display: none;
  }
}

/*
*	Styles de l'outil d'administration
// scss-lint:disable ImportantRule, QualifyingElement, IdSelector, SelectorFormat
*/

.wp-admin {
  td.page-title {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
  }

  .badge {
    font-size: 12px;
    font-weight: 400;
  }

  .mce-menu-item {
    > .mce-text {
      font-size: 14px !important;
      font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif !important;
      text-transform: none !important;
      font-weight: 400 !important;
      font-style: normal !important;
    }

    &.mce-menu-item-preview.mce-active {
      > .mce-text {
        color: $white;
      }
    }
  }

  .disabled {
    pointer-events: none;

    > * {
      opacity: .5;
    }
  }

  .acf-field select {
    padding: 2px 8px !important;
  }

  #wpfooter,
  #wp-admin-bar-wp-logo,
  #wp-admin-bar-new-content,
  #wp-admin-bar-my-account .avatar {
    display: none !important;
  }

  #wp-admin-bar-site-name .ab-item {
    padding-left: 8px;
  }

  .hide-if-no-customize {
    display: none !important;
  }

  .widefat th,
  .widefat td {
    padding: 10px 8px !important;

    input[type='checkbox'] {
      margin-top: 1px;
    }
  }

  th.sortable a,
  th.sorted a {
    padding: 6px 0 !important;
  }

  .smaller-editor > .acf-input {
    margin-top: ln(-2);
  }

  .acf-field[data-type='tab'] + .smaller-editor > .acf-input {
    margin-top: 0;
  }

  #translation_of_wrap,
  #icl_document_language_dropdown,
  #icl_translation_priority_dropdown,
  .otgs-toggle-group,
  #icl_cfo,
  #icl_set_duplicate,
  .otgs-ico-help {
    display: none;
  }

  #icl_translations_table,
  .icl_box_paragraph {
    margin-bottom: 0 !important;
  }

  .acf-repeater.-table.-empty .acf-table {
    display: none;
  }

  #dashboard-widgets .postbox-container .meta-box-sortables {
    &::after {
      display: none !important;
    }
  }

  .inside > br[clear='all'] {
    display: none;
  }

  #icl_translate_options {
    margin-bottom: 15px;

    ~ strong {
      display: inline-block;
      margin-bottom: 8px;
    }
  }

  #local-storage-notice,
  #icl_translate_options + br,
  #icl_translate_options + br + br,
  #icl_translate_options ~ strong + br,
  .otgs-ico-help + br,
  .otgs-ico-help + br + br,
  .toplevel_page_wpseo_dashboard .update-plugins,
  .acfml-synchronise-repeater-checkbox {
    display: none !important;
  }

  .icl_box_paragraph > p:first-child {
    margin-bottom: 8px;
  }
}

.no-label.acf-field > .acf-label {
  display: none !important;
}
